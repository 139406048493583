<template>
    <v-card color="#193453">
        <v-card-text class="d-flex align-center">
            <div class="containerTimer">
                <div class="flex-shrink-0">
                    <div id="svg-container">
                        <svg id="circle2" viewBox="0 0 380 380">
                            <rect x="155" y="10" width="70" height="20" style="fill: #ccc"/>
                            <rect x="170" y="20" width="40" height="30"  style="fill: #ccc"/>
                            <rect x="250" y="-160" width="50" height="20" transform="rotate(45)" style="fill: #ccc"/>
                            <circle  cx="190" cy="200" r="145" />
                        </svg>
                        <svg id="circle" ref="svg" viewBox="0 0 340 340" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        </svg>
                        <div class="contentMinutes">
                            <span :style="'color:'+currentTxtColor">{{ contentMinutes ? contentMinutes : config.duration }}</span>
                            <div class="min">min</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" flex-grow-1">
                <div :style="'font-size:32px; font-weight:bold; color:'+currentTxtColor" v-if="currentTxt && currentTxt != ''" class="text-center flex-grow-0">{{ currentTxt }}</div>
                <div style="text-align:center;" v-else>
                    <v-btn @click="launchTimer" outlined color="white" rounded ><b class="success--text" style="font-size:24px;">Lancer !</b></v-btn>
                </div>
                <div class="timerActions"  v-if="currentTxt && currentTxt != ''">
                    <v-btn text class="square">
                        <v-icon color="white" @click="resetTimer">$arrowsrotate_s</v-icon>
                    </v-btn>
                    <v-btn text class="square">
                        <v-icon color="white" @click="setPause">$playpause_s</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "countdownCircle",
    props: ['config', 'autostart'],
    data() {
        return {
            currentKey:0,
            offset:3,
            totalSegments: 60,
            filledSegments:0,
            content: "",
            contentMinutes: "",
            currentColor: "#FFF",
            currentTxt: "",
            currentTxtColor: "#FFF",
            timer : "",
            firstIteration : true,
            isPaused: false,
        }
    },
    created() {
        setTimeout(() => {
            this.draw()
        }, 500);
    },
    destroyed(){
        clearInterval(this.timerInterval);
    } ,
    watch: {
        timer (newCount, oldCount) {
            if(this.config && this.config.break){
                for (const key in this.config.break) {
                    if (Object.hasOwnProperty.call(this.config.break, key)) {
                        const element = this.config.break[key];
                        if((newCount/60) < key){
                            this.currentColor = element.color
                            if(this.filledSegments == 1){
                                this.currentTxt = element.txt
                                this.currentTxtColor = element.color
                            }
                        }
                    }
                }
            }

            if(this.config && this.config.jingle){
                for (const key in this.config.jingle) {
                    if (Object.hasOwnProperty.call(this.config.jingle, key)) {
                        const element = this.config.jingle[key];
                        if(newCount == key) {
                            this.$emit('showJingle', element);
                        }
                    }
                }
            }
        },
        autostart (newVal, oldVal) {
            if(newVal)
                this.launchTimer();
        }
    },
    methods: {
        resetTimer: function(){
            clearInterval(this.timerInterval);
            this.filledSegments = 0
            this.currentColor = '#FFF'
            this.currentTxtColor = '#FFF'
            this.currentTxt = ""
            this.timer = ""
            this.isPaused = false
            this.contentMinutes = ""
            this.draw()
            this.$emit('timerReset')
        },
        setPause: function(){
            if(!this.isPaused){
                this.isPaused = true,
                this.currentColor = this.config.pauseConfig.color;
                this.currentTxtColor = this.config.pauseConfig.color;
                this.currentTxt = this.config.pauseConfig.txt;
                let filledSegment = document.getElementsByClassName('filled'+this.config.id);
                filledSegment.forEach(element => {
                    element.style.stroke = this.config.pauseConfig.color;
                });
                this.$emit('timerPause')
            } else {
                let changed = false;
                for (const key in this.config.break) {
                    if (Object.hasOwnProperty.call(this.config.break, key)) {
                        const element = this.config.break[key];
                        if((this.timer/60) < key){
                            this.currentColor = element.color
                            this.currentTxt = element.txt
                            this.currentTxtColor = element.color
                            changed = true;
                        }
                    }
                }
                if(!changed){
                    this.currentColor = this.config.color
                    this.currentTxt = this.config.txt
                    this.currentTxtColor = this.config.color
                }
                this.isPaused = false
                this.$emit('timerUnpause')
            }
        },
        launchTimer: function() {
            if(this.config && this.config.offsetStart  && this.config.offsetStart > 0){
                setTimeout(() => {
                    this.startTimer(this.config.duration * 60)
                    this.$emit('timerStartOffset')
                }, (this.config.offsetStart * 1000));
            } else {
                this.startTimer(this.config.duration * 60)
            }
            this.$emit('timerStart')
        },
        startTimer: function (duration) {
            var minutes 
            var seconds 
            this.currentTxt = this.config.txt;
            this.currentColor = this.config.color;
            this.currentTxtColor = this.config.color;
            this.timer = duration, minutes, seconds;
            const vm = this
            this.timerInterval = setInterval(function () {
                if(!vm.isPaused){
                    minutes = parseInt(vm.timer / 60, 10);
                    seconds = parseInt(vm.timer % 60, 10);

                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    seconds = seconds < 10 ? "0" + seconds : seconds;

                    vm.content = minutes + ":" + seconds;
                    vm.contentMinutes = minutes;
                    if(!vm.firstIteration)
                        vm.render()
                    if (--vm.timer < 0) {
                        vm.resetTimer()
                        vm.$emit('timerEnd')
                    }
                    vm.firstIteration = false
                }
            }, 1000);
        },
        render: function(){
            if(this.filledSegments == this.totalSegments)
                this.filledSegments = 1
            else
                this.filledSegments++;
            this.draw()
        },
        draw: function(){
            var len = this.offset === 0 ?
					(360 / this.totalSegments) :
					(360 - (this.offset * this.totalSegments)) / this.totalSegments,
				prevStartAngle = 0,
				prevEndAngle = 0,
				segment = '', i;
            if (this.offset === 0 && this.totalSegments === 1) {
                
                segment = '<circle cx="170" cy="170" r="130"';
                segment += this.filledSegments === 1 ? ' class="filled"/>' : ' />'
            } else {
                for (i = 1; i <= this.totalSegments; i++) {
                    prevStartAngle = prevEndAngle + this.offset;
                    prevEndAngle = (len * i) + (this.offset * i);
                    segment += '<path ';
                    segment += this.filledSegments >= i ? 'class="filled'+this.config.id+'" style="stroke: '+this.currentColor+';" ' : '';
                    segment += 'd="';
                    segment += this.describeArc(170, 179, 110, prevStartAngle, prevEndAngle);
                    segment += '"/>';
                }
            }
            this.$refs.svg.innerHTML = segment;
        },
        describeArc: function(x, y, radius, startAngle, endAngle){
            var start = this.polarToCartesian(x, y, radius, endAngle),
				end = this.polarToCartesian(x, y, radius, startAngle),
				arcSweep = endAngle - startAngle <= 180 ? '0' : '1',
				d = [
					'M', start.x, start.y,
					'A', radius, radius, 0, arcSweep, 0, end.x, end.y
				].join(' ');
		    return d;
        },
        polarToCartesian: function(centerX, centerY, radius, angleInDegrees) {
            var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
            return {
                x: centerX + (radius * Math.cos(angleInRadians)),
                y: centerY + (radius * Math.sin(angleInRadians))
            };
        }
    },
}
</script>

<style lang="scss">
.containerTimer {
    background-color:#193453;
}
#svg-container {
    position:relative;
}
    svg#circle, svg#circle2 {
        width:100%;
        max-width:200px;
        path, circle {
            fill: none;
            stroke-width: 15;
            stroke: #ccc;
            &.filled {
                stroke: #6DD5EF;
            }
        }
    }
    svg#circle2 {
        position:absolute;
    }
    .contentMinutes {
        position:absolute;
        font-size:62px;
        font-weight:bold;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        text-align:center;
        line-height: 62px;
        .min {
            font-size:28px;
            color:white;
        }
    }
    .timerActions {
        position:absolute;
        bottom:10px;
        right:10px;
    }
</style>